import React, { useState, useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import  {getSingleBlogAction}  from "../../redux/reducers/meta/blog/getSingleBlog";
import  {editBlogAction}  from "../../redux/reducers/meta/blog/editBlog";
import  {uploadFileAction}  from "../../redux/reducers/uploadFile";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import Header from "../../components/Header";
import JoditEditor from "jodit-react";
import { Toast } from "../../components/Toast";
import { Card, CardContent, Stack, Select,TextField, MenuItem,FormControl, InputLabel } from "@mui/material";

const EditBlog = () => {
  const [data, setData] = useState({
    title: "",
    description: "",
    readingTime: "",
    author: "",
    image: "",
    category: "",
    metaDescription: "",
    smallDescription: "",
    content: "",
    metaUrl: "",
    is_active:false,
  });
  const [file, setFile] = useState(null); 
  const [snake, setSnake] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogId } = useParams();

  const { blog, loading: fetchLoading } = useSelector((state) => state.getSingleBlog);
  const { loading: submitLoading, message, status } = useSelector((state) => state.editBlog);

  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
    }),
    []
  );

  useEffect(() => {
    if (blogId) {
      dispatch(getSingleBlogAction(blogId));
    }
  }, [dispatch, blogId]);

  useEffect(() => {
    if (blog) {
      setData({
        title: blog?.data.title || "",
        description: blog?.data.description || "",
        readingTime: blog?.data.readingTime || "",
        author: blog?.data.author || "",
        image: blog?.data.image || "",
        category: blog?.data.category || "",
        metaDescription: blog?.data.metaDescription || "",
        smallDescription: blog?.data.smallDescription || "",
        content: blog?.data.content || "",
        is_active: blog?.data.is_active || false,
        metaUrl: blog?.data.metaUrl || "",
      });
    }
  }, [blog]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files && files[0]) {
      setFile(files[0]);
      const imageUrl = URL.createObjectURL(files[0]);
      setData((prev) => ({ ...prev, image: imageUrl }));
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        const formData = new FormData();
        formData.set("file", file);
        const res = await dispatch(uploadFileAction(formData));
  
        if (res?.payload?.status !== 200) {
          return;
        }
        data.image = res?.payload?.fileUrl;
      }
  
      if (Object.keys(data).length === 0 || Object.values(data).some(value => !value)) {
        // return;
      }
  
      const payload = {
        blogId,
        ...data
      };
  
      const result = await dispatch(editBlogAction(payload));
      setSnake(true);
      console.log(`result`,result);
      if (result?.payload?.status === 200) {
        setTimeout(() => {
          navigate('/view/all/blog');
        }, 2000);
      } else {
        console.error(result.payload.message);
      }
    } catch (error) {
      console.error("Error submitting blog:", error);
    }
  };
  
  return (
    <>
      <Header />
      <div className="container-2">
        <h3>Edit Blog</h3>
        <br />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            gap: "15px",
            flexWrap: "wrap",
          }}
        >
          {/* <div className="input-box">
            <label htmlFor="title">Title</label>
            <TextField
              value={data?.title}
              onChange={handleInputChange}
              name="title"
              fullWidth
              type="text"
              variant="outlined"
              size="small"
            />
          </div> */}
           <CardContent>
            <h3>Title</h3>
            <JoditEditor
              ref={editor}
              value={data?.title}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setData((prev) => ({ ...prev, title: newContent }));
              }}
            />
          </CardContent>

          <div className="input-box">
            <label htmlFor="author">Author</label>
            <TextField
              name="author"
              onChange={handleInputChange}
              fullWidth
              value={data?.author}
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          {/* <div className="input-box">
            <label htmlFor="category">category</label>
            <TextField
              name="category"
              onChange={handleInputChange}
              fullWidth
              value={data?.category}
              type="text"
              variant="outlined"
              size="small"
            />
          </div> */}
          <Card elevation={3} sx={{ width: "100%", mt: 5, background: "whitesmoke" }}>
            <CardContent>
              <h3>Category</h3>
              <JoditEditor
                ref={editor}
                value={data?.category}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => {
                  setData((prev) => ({ ...prev, category: newContent }));
                }}
              />
            </CardContent>
          </Card>
          <div className="input-box">
            <label htmlFor="metaUrl">meta Url</label>
            <TextField
              name="metaUrl"
              onChange={handleInputChange}
              fullWidth
              value={data?.metaUrl}
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="readingTime">Reading Time</label>
            <TextField
              fullWidth
              name="readingTime"
              onChange={handleInputChange}
              type="number"
              value={data?.readingTime}
              variant="outlined"
              size="small"
            />
          </div>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            gap: "15px",
            flexWrap: "wrap",
          }}
        >
          <div className="input-box">
            <label htmlFor="blog-image">Blog Image</label>
            <img
              src={data?.image}
              alt="Blog"
              style={{ maxWidth: "100%", height: "auto", marginTop: "10px" }}
            />
          </div>
          <div className="input-box">
            <label htmlFor="image">Upload New Image</label>
            <TextField
              name="image"
              onChange={handleInputChange}
              inputProps={{
                accept: "image/*",
              }}
              fullWidth
              type="file"
              variant="outlined"
              size="small"
            />
          </div>
        </Stack>

        <Card elevation={3} sx={{ width: "100%", mt: 5, background: "whitesmoke" }}>
          <CardContent>
            <h3>Small Description</h3>
            <JoditEditor
              ref={editor}
              value={data?.description}
              config={config}
              tabIndex={1}
              onBlur={(newContent) =>
                setData((prev) => ({ ...prev, smallDescription: newContent }))
              }
            />
          </CardContent>
        </Card>
        <Card elevation={3} sx={{ width: "100%", mt: 5, background: "whitesmoke" }}>
          <CardContent>
            <h3>Is Active</h3>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="is-active-label">Is Active</InputLabel>
              <Select
                labelId="is-active-label"
                id="is-active"
                name="is_active"
                value={data?.is_active}
                onChange={handleInputChange}
                label="Is Active"
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>

        <Card elevation={3} sx={{ width: "100%", mt: 5, background: "whitesmoke" }}>
          <CardContent>
            <h3>Content</h3>
            <JoditEditor
              ref={editor}
              value={data?.content}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setData((prev) => ({ ...prev, content: newContent }));
              }}
            />
          </CardContent>
        </Card>

        <div className="submit-btn-box">
          <button className="btn-submit" onClick={handleSubmit} disabled={submitLoading || fetchLoading}>
            Submit
          </button>
        </div>
        <Toast
        open={snake}
        setOpen={setSnake}
        message={message}
        status={status}
      />
      </div>
    </>
  );
};

export default EditBlog;
